<template>
  <div class="world login">
    <div class="login-wrapper">
      <div class="title">
        <h1>{{ $t('Wilms World - Wachtwoord herstellen') }}</h1>
        <div class="intro">
          <p>{{ $t('Voer hieronder jouw e - mailadres in om zo je wachtwoord opnieuw in te kunnen stellen.') }}</p>
        </div>
      </div>

      <div class="message" :class="messageType + '-message'" v-if="message">{{ message }}</div>

      <formulate-form v-model="formValues" @submit="passwordResetRequest">
        <formulate-input validation="required" error-behavior="submit" type="email" name="email" validation-name="E-mailadres" :placeholder="$t('E-mailadres')"></formulate-input>
        <formulate-input type="submit" :label="isLoading ? $t('Even geduld...') : $t('Verstuur')"></formulate-input>
      </formulate-form>

      <div class="no-account-info">
        <h3>{{ $t('Nog geen Wilms World account?') }}</h3>
        <a class="btn" href="https://www.wilms.be/contact/algemene-vraag?type=ask-for-login">{{ $t('Vraag account aan') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'

export default {
  data () {
    return {
      isLoading: false,
      formValues: {},
      message: null,
      messageType: null
    }
  },
  mounted () {
    document.body.classList.add('login')
  },
  methods: {
    passwordResetRequest: async function (formData) {
      await ApiService.requestPasswordReset(formData.email)
        .then(() => {
          this.messageType = 'success'
          this.message = this.$t('Wanneer dit e-mailadres gekend is zal je een email ontvangen met daarin je wachtwoord reset link.')
        }).catch(() => {
          this.messageType = 'error'
          this.message = this.$t('Incorrect e-mailadres.')
        })
    }
  }
}
</script>
